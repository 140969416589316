<template>
    <div class="login-screen">
        <div class="left-login-area flex flex-col justify-center items-center">
            <idc-logo class="d-block mb-4" width="100%" height="48px" color="white"/>
            <h3 class="text-center mb-3 text-white">
                {{ lang.sendRecoveryEmail.welcomeMessage }}
            </h3>
        </div>
        <div class="right-login-area flex-col container mx-auto flex p-3">

            <idc-logo class="block md:hidden lg:hidden mb-6" width="100%" height="48px" color="black"/>

            <p class="text-center mx-8 md:mx-8 lg:mx-32 text-2xl mb-4">
                {{ lang.sendRecoveryEmail.helpEmailMessage }}
            </p>

<!--            <form @submit.prevent="sendRecoveryEmail(guest)" class="w-full xl:w-1/2" @keydown="removeError($event.target.id)">-->
            <form class="w-full xl:w-1/2">
                <a-input
                    :label="lang.common.email"
                    id="email"
                    type="email"
                    :error="error('email', { attribute: lang.common.email })"
                    v-model="guest.email" />

                <div class="flex flex-col items-center mt-4">

                    <a-button :loading="loading" submit class="w-full" @click="sendRecoveryEmail(guest)">
                        Confirmar
                    </a-button>

                    <div class="flex items-center justify-between space-x-4">
                        <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
                        <h1 class="text-2xl text-gray-500 py-8">O</h1>
                        <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
                    </div>

                    <router-link class="text-primary font-bold" :to="{ name: 'empty' }">
                        Iniciar sesión
                    </router-link>
                </div>
<!--                <div class="form-group mx-8 md:mx-8 lg:mx-32">-->
<!--                    <a-button :loading="loading" submit class="w-full justify-center">-->
<!--                        {{ lang.common.confirm }}-->
<!--                    </a-button>-->
<!--                </div>-->
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import IdcLogo from '@/components/ui/IDCLogo.vue';
export default {
    data: () => ({
        guest: {
            email: ''
        }
    }),
    computed: {
        ...mapGetters({
            error: 'error',
            hasError: 'hasError',
            lang: 'lang'
        }),
        ...mapState({
            loading: state => state.session.loading
        })
    },
    methods: mapActions({
        translate: 'translate',
        removeError: 'removeError',
        sendRecoveryEmail: 'session/sendRecoveryEmail'
    }),
    components: {
        IdcLogo
    },
    mounted() {
        this.guest.token = this.$route.query?.token
    }
}
</script>

<style scoped>
  .login-screen {
      min-height: 100vh;
      display: grid;
      grid-template-columns: 1fr 1fr;
  }

  .login-screen > .left-login-area, .login-screen > .right-login-area {
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  .login-screen > .left-login-area {
    background: #4D4F5C;
  }

  .login-screen > .right-login-area {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 800px) {
      .login-screen {
          grid-template-columns: 1fr;
      }

      .left-login-area {
          display: none !important;
      }
  }
</style>
